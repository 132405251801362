import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/ClassAssembly/2023/7A/1.jpg';
import p2 from 'assests/Photos/ClassAssembly/2023/7A/2.jpg';
import p3 from 'assests/Photos/ClassAssembly/2023/7A/3.jpg';
import p4 from 'assests/Photos/ClassAssembly/2023/7A/4.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly2023';

const ClassAssembly7A2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
        src: p3,
        source: p3,
        rows: 1.2,
        cols: 2,
    },
    {
      src: p1,
      source: p1,
      rows: 1.3,
      cols: 2,
    },
    {
        src: p4,
        source: p4,
        rows: 1.3,
        cols: 2,
    },
    {
      src: p2,
      source:p2,
      rows: 1.2,
      cols: 2,
    },  
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
        Diwali  
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 7A   Date: 10 NOVEMBER 2023

        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        Students of class 7A presented an informative assembly on the topic, “Diwali” on Thursday 10 December 2023. The assembly highlighted the significance of celebrating  the festival of lights. It began with an introduction which highlighted why we must celebrate Diwali which was followed by the thought for the day. 
        <br></br>
        The students conveyed the assembly through a skit and a dance. The skit emphasized on how people from different parts of India celebrate the festival of Diwali. The students performed a beautiful and energetic dance pertaining to how the people from different parts of India celebrate Diwali.
The assembly conveyed the importance of celebrating Diwali and the mythology behind the festival. There is always light in the darkness, but we just have to look for it.

        </Typography>
        </Box>
        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
        “CARRRY THE LIGHT OF DIWALI WITH YOU AND REMEMBER IT ON THE DARKER DAYS OF YOUR LIFE”
        <br/>
        </Typography>
        <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly7A2023;